import { RasaContext } from 'context'
import * as React from 'react'
import { Col, Row } from 'reactstrap'
import { Roles } from 'shared/constants'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { BillingPlanDetailCode } from 'shared_server_client/types/billing_plan'
import { ArticleImageUpload } from './article-image-upload'
import { ArticleImageUrlInput } from './article-image-url-input'
import { Font } from './constants'

interface AddArticlePreviewProps {
  data: any,
  description: any,
  imageHeight: number,
  imageUrl: string,
  imageWidth: number,
  isUploading: boolean,
  uploadImage: any,
  clearImage: any,
  font?: Font,
  updateImageUrl?: any,
  useUrlClick?: any,
}

interface AddArticlePreviewState {
  errorMessage: string,
  hasArticleImageUrlAccess: boolean,
  imageUrl: string,
  isSuperUser: boolean,
  isUploading: boolean,
  showUrlInput: boolean,
  validUrl: boolean,
}
export class AddArticlePreview extends React.Component<AddArticlePreviewProps, AddArticlePreviewState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore
  constructor(props: any) {
    super(props)
    this.state = {
      errorMessage: '',
      hasArticleImageUrlAccess: false,
      imageUrl: '',
      isSuperUser: false,
      isUploading: false,
      showUrlInput: false,
      validUrl: false,
    }
    this.updateImageUrl = this.updateImageUrl.bind(this)
  }
  public componentDidMount = () => {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.role),
      this.sharedStore.getValue(SharedKeys.activeCommunity),
    ]).then(([role, activeCommunity]) => {
      const avlFeatures: BillingPlanDetailCode[] = activeCommunity.billingInfo.currentPlan.features || []
      this.setState({
        isSuperUser: role === Roles.super_admin,
        hasArticleImageUrlAccess: avlFeatures.indexOf(BillingPlanDetailCode.ARTICLE_IMAGE_URL) > -1,
      })
    })
  }
  public render() {
    return <div className="article-desc">
    <Row>
      <Col sm={3}>
        <ArticleImageUpload isUploading={this.state.isUploading}
          allowUseUrl={this.state.hasArticleImageUrlAccess || this.state.isSuperUser}
          useUrlClick={() => this.setState({showUrlInput: true})}
          imageUrl={this.props.imageUrl}
          uploadImage={this.props.uploadImage} clearImage={this.props.clearImage}
          imageHeight={this.props.data.image_height} imageWidth={this.props.data.image_width} />
      </Col>
      <Col lg={9}>
      <b>{this.props.data.title}</b><br/>
      <span className="article-description">{this.props.description}</span>
      </Col>
      </Row>
      {this.state.showUrlInput &&
      <Row>
        <Col sm={12}>
          <ArticleImageUrlInput isUploading={this.state.isUploading}
            updateImageUrl={this.updateImageUrl}
            cancelUpload={() => this.setState({showUrlInput: false})} />
        </Col>
      </Row>}
  </div>
  }
  protected updateImageUrl(imageUrl) {
    this.props.updateImageUrl(imageUrl)
    this.setState({
      showUrlInput: false,
    })
  }
}
