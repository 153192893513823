import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import { GeneratedSummaryModalComponent } from 'components/generate-text'
import { RasaContext } from 'context'
import * as GenericRedux from 'generic/genericRedux'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import * as React from 'react'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { ModalSize } from 'shared/modals/constants'
import { AddEditArticleModal } from './addEditArticleModal'
import { Post } from './edit-article/types'
import { DEFAULT_COMMUNITY_PARTNER_CODES } from 'shared_server_client/constants'
import { ImageGalleryModal } from '../common/image-gallery/modal'

type AddArticleProps = GenericRedux.AllComponentPropsWithModal<Post>
export class AddArticlePageModalComponent extends RasaReactComponent<AddArticleProps, any> {
  public static contextType = RasaContext
  private sharedStore: SharedStore

  constructor(p: AddArticleProps) {
    super(p, 'rasaCommunityArticle')
  }

  public componentDidMount() {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
    ]).then(([activeCommunity]) => {
      this.props.openModal(AddEditArticleModal.key, {
        communityId: activeCommunity.communityId,
      })
    })
  }

  public render() {
    return (
      <div className="add-article-page">
        <AddEditArticleModal
          data={this.props.modals}
          allowSegment={true}
          defaultSegmentCode={DEFAULT_COMMUNITY_PARTNER_CODES.ALL}
          allowGeneratingViaChatGPT={true}
          isScheduled={false}
          updateModal={this.props.updateModal}
          openModal={this.props.openModal}
          saveModal={this.props.saveModal}
          closeModal={this.props.closeModal}
          afterClose={this.forceReload}
          forceReload={this.forceReload}
          title="Add Article"
          size={ModalSize.Large} />
      </div>
    )
  }

  private forceReload = () => {
    this.props.push(DashboardMenuOption.content)
  }

}
export const AddArticlePageModal = GenericRedux.registerNewComponentWithModals<Post>(
  AddArticlePageModalComponent, 'rasaCommunityArticle',
  [AddEditArticleModal.key, GeneratedSummaryModalComponent.key, ImageGalleryModal.key])
