import React from 'react'
import * as Modals from 'shared/modals'
import * as Constants from '../constants'
import { ArticleAction } from '../types'

export class ArticleAlertModal extends Modals.ModalComponent {
  public static key: string = 'addArticle'
  constructor(props: any) {
    super(props, ArticleAlertModal.key)
  }
  protected renderChildren(data: any) {
    return <div>
      {
        data === ArticleAction.FETCH ?
        Constants.FETCH_ARTICLE_ERROR : Constants.ADD_ARTICLE_ERROR
      }
    </div>
  }
}
