import { InitialScheduledContentModalState } from 'components/schedule-editor/scheduled-content/constants'
import { AddArticleModalBaseComponent, AddArticleModalProps } from 'components/schedule-editor/scheduled-content/modals/addArticleBaseComponent'
import { BaseClientEntity } from 'generic/baseClientEntity'
import { isInCutoffWindow } from 'shared/data-layer/issues'
import { NORMAL_BOOSTED_BOOST_LEVEL } from 'shared_server_client/constants'

export class AddEditArticleModal extends AddArticleModalBaseComponent {
  public static key: string = 'regularArticleModal'

  constructor(props: AddArticleModalProps) {
    super(props, AddEditArticleModal.key)
    this.state = InitialScheduledContentModalState
  }

  protected createOrUpdate(payload: any): Promise<any> {
    return this.context.entityMetadata
      .getEntityObject('rasaCommunityArticle', payload.communityId, payload.typeId)
      .then((entity: BaseClientEntity) => {
        const {typeId} = payload
        entity.setRecordId(payload.communityId, typeId && !payload.isCloneItem ? typeId : null)
        entity.data.url = payload.url
        entity.data.override_url = payload.override_url
        entity.data.post_subscription_id = payload.post_subscription_id

        if ( this.fieldChanged('description') ) {
          entity.data.description = payload.description
        }
        if ( this.fieldChanged('title') ) {
          entity.data.title = payload.title
        }
        if ( this.fieldChanged('publisher') ) {
          entity.data.site_name = payload.publisher
        }
        if ( this.fieldChanged('image_alt_text') ) {
          entity.data.image_alt_text = payload.image_alt_text
        }
        if (payload.hostedImage && this.fieldChanged('hostedImage')) {
          entity.data.hosted_image_url = payload.hostedImage.url
          entity.data.image_height = payload.hostedImage.height
          entity.data.image_width = payload.hostedImage.width
          entity.data.image_url = payload.hostedImage.url
        } else if (payload.image && this.fieldChanged('image')) {
          entity.data.image_url = payload.image
        }
        if (payload.isReserved) {
          entity.data.is_reserved = true
        }
        entity.data.custom_tags = payload.customTags
        entity.data.content_category =  payload.contentCategory
        entity.data.category_type_id = payload.categoryTypeId
        entity.data.community_partner_code = payload.community_partner_code
        if (!payload.typeId) {
          // Only do this if we are creating a new article
          // Pass community_identifier
          entity.data.communityIdentifier =  payload.communityId
          if (isInCutoffWindow(this.state.activeCommunity.nextIssue)) {
            // Mark the article as boosted if it is in the cutoff window and set the created date to the cutoff date
            entity.data.boost_level = NORMAL_BOOSTED_BOOST_LEVEL
            entity.data.created = this.state.activeCommunity.nextIssue.cutoff
          }
        }
        return entity.save()
      })
  }

  protected doSave(data: any): Promise<any> {
    const articlePromise = [super.doSave(data)]
    if (data.forceUpdate && data.forceUpdate.length) {
      const duplicateArticle = data.forceUpdate.map((e) => {
        const cloneArticle = {
          typeId: e.id,
          title: data.title,
          description: data.description,
          image_url: data.image_url,
          hosted_image_url: data.hosted_image_url,
          image_height: data.image_height,
          image_width: data.image_width,
          image_alt_text: data.image_alt_text,
          customTags: data.custom_tags,
          hostedImage: data.hostedImage,
          communityId: data.communityId
        }
        return this.createOrUpdate(cloneArticle)
      })
      articlePromise.push(...duplicateArticle)
    }
    return Promise.all(articlePromise)
  }
}
