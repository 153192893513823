import { DatePicker, DateTimePicker } from '@progress/kendo-react-dateinputs'
import classnames from 'classnames'
import { startCase } from 'lodash'
import * as React from 'react'
import { Badge, Button, Input } from 'reactstrap'
import { Editor as TinyMCEEditor } from 'tinymce'

import { sizedUrl } from '../../app/utils'
import * as Validation from '../validation/validations'
import { BaseClientEntity } from '../../generic/baseClientEntity'
import { DATEPICKER_DATE_FORMAT, DATEPICKER_DATETIME_FORMAT } from '../../shared/constants'
import { isInCutoffWindow } from '../../shared/data-layer/issues'
import * as Modals from '../../shared/modals'
import {
  FormatTypes,
  NORMAL_BOOSTED_BOOST_LEVEL,
  ScheduledContentTypes,
  SCHEDULED_ARTICLES_BOOST_LEVEL } from '../../shared_server_client/constants'
import { endOfDay, startOfDay } from '../../shared_server_client/dates'

import { LoadSegmentCodes } from '../common/load-partner-codes/component'
import { ImageGalleryModal } from '../common/image-gallery/modal'
import { DropdownComponent } from '../dropdown/component'
import { LinkOutIcon } from '../icons/link-out'
import { UrlInput } from '../input/component'
import { Loading, SIZES } from '../loading'
import { GeneratedSummaryModalComponent } from '../generate-text'
import {
  CONTROL_CONTAINER, CONTROL_FETCH_BUTTON,
  InitialScheduledContentModalState
} from '../schedule-editor/scheduled-content/constants'
import {
  AddArticleModalBaseComponent,
  AddArticleModalProps
} from '../schedule-editor/scheduled-content/modals/addArticleBaseComponent'
import { Tinymce_Default_Config, TinymceEditorComponent } from '../tinymce-editor/tinymceditor'

import { ArticleImageUpload } from './article-image-upload'
import { Font } from './constants'

export class AddEditEventModal extends AddArticleModalBaseComponent {
  public static key: string = 'addEditEventModal'
  constructor(props: AddArticleModalProps) {
    super(props, AddEditEventModal.key)
    this.state = InitialScheduledContentModalState
  }

  public renderChildren(data) {
    const { contentCategory, customTags, description, id, image, hostedImage, nlpTags,
      publisher, sourceTags, options, title, url, image_alt_text } = data

    return this.state.isSaving
      ? <Loading size={SIZES.LARGE}/>
      : <div className="scheduled-article-modal-body sched-art">
        <div className="full-width-container">
          <div className="first-row-container">
            <GeneratedSummaryModalComponent
              saveModal={(key, modalData) => {
                if ( modalData.description ) {
                  this.updateProperty('description', modalData.description)
                }
                return this.props.closeModal(key)
              }}
              title={title}
              description={description}
              url={url}
              data={this.props.data}
              closeModal={this.props.closeModal}
              updateModal={this.props.updateModal}
              size={Modals.ModalSize.Large} />
            {this.renderArticleUrlField(data)}
          </div>
          <div className={classnames(this.getClassNames(data, CONTROL_CONTAINER))}>
            <div className="second-row-container">
              <h5 className="heading">Title</h5>
              <div className="second-row-inner-container">
                <Input type="text"
                       value={title ? title : ''}
                       onChange={(e) => this.updateProperty('title', e.target.value)}/>
              </div>
            </div>
            <div className="third-row-container">
              <h5 className="heading">Source</h5>
              <div className="third-row-inner-container">
                <Input type="text"
                       value={publisher ? publisher : ''}
                       onChange={(e) => this.updateProperty('publisher', e.target.value)}/>
              </div>
            </div>
            <div className="third-row-container">
              <h5 className="heading">Registration Url</h5>
              <div className="third-row-inner-container">
                <Input type="text"
                       value={options ? options.registration_url : ''}
                       onChange={(e) => this.updateProperty('options', {
                         ...options,
                         registration_url: e.target.value
                       })}/>
              </div>
            </div>
            <div className="third-row-container">
              <h5 className="heading">Date/Time</h5>
              <div className="third-row-inner-container">
                <DateTimePicker
                  className="modal-schedule-datetime-picker"
                  format={DATEPICKER_DATETIME_FORMAT}
                  value={ options && options.start_time ? new Date(options.start_time) : null }
                  onChange={(e) => this.updateProperty('options', {
                    ...options,
                    start_time: e.target.value.toISOString()
                  })}
                />
              </div>
            </div>
            <div className="second-row-container">
              <div className="heading">
                <h5>Excerpt</h5>
                {this.props.allowGeneratingViaChatGPT &&
                    <Button className="generate"
                            onClick={() => this.props.openModal(
                              GeneratedSummaryModalComponent.key, {source: 'edit-article'})}>
                        <span>AI Generated</span>
                    </Button>}
              </div>
              <div className="fourth-row-inner-container">
                <TinymceEditorComponent
                  html={description ? description : ''}
                  height='413'
                  tinymceConfig={{
                    ...Tinymce_Default_Config,
                    menu: {
                      file: { title: '', items: '' },
                      edit: { title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall | searchreplace' },
                      view: { title: '', items: '' },
                      insert: { title: 'Insert', items: 'link' },
                      format: { title: 'Format', items: 'bold italic underline | forecolor backcolor | removeformat' },
                      table: { title: '', items: '' },
                      tools: { title: '', items: '' },
                    },
                    plugins: [
                      'emoticons', 'lists', 'link', 'searchreplace',
                    ],
                    toolbar: 'bold italic underline | forecolor backcolor | bullist numlist | emoticons | link',
                  }}
                  instance={(editor: TinyMCEEditor) =>  this.tinymceInstance(editor)}
                  onChange={(html: string) => this.articleDescription(html) }
                  showCharacterCount={true} />
              </div>
            </div>
          </div>
          <div className="body-container">
            <div className="left-side-container">
              <div className={classnames(this.getClassNames(data, CONTROL_CONTAINER))}>
                <div className="first-row-container">
                  <h5>Image Preview</h5>
                  <div className="preview-container">
                    {<ArticleImageUpload isUploading={this.state.isUploading} allowAIGeneration={true}
                     allowUseUrl={true}
                     allowImageLibrary={true}
                     imageUrl={sizedUrl(image, 250, 400)}
                     font={Font.LARGE}
                     updateImageUrl={this.updateImageUrl}
                     aiGenerateImageClick={this.aiGenerateImageClick}
                     uploadImage={this.uploadImage} clearImage={this.clearImage}
                     imageHeight={hostedImage ? hostedImage.height : null}
                     imageWidth={hostedImage ? hostedImage.width : null}
                     selectImageLibrary={this.openImageGalleryModal}/>}
                  </div>
                  <div>
                    <h5 className="heading">Alt Text</h5>
                    <Input type="text"
                           value={image_alt_text ? image_alt_text : ''}
                           onChange={(e) => this.updateProperty('image_alt_text', e.target.value)}/>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-side-container">
              <div className={classnames(this.getClassNames(data, CONTROL_CONTAINER))}>
                <div className="single-row-container">
                  {this.props.allowSegment &&
                      <div
                          className={`third-row-inner-container right-margin ${!this.state.showSegments ? 'display-none' : ''}`}>
                          <h5>Segment</h5>
                          <LoadSegmentCodes
                              hideLabel={true}
                              setSegmentData={this.setSegmentData}
                              segmentCode={data.community_partner_code}
                              setSegmentCode={this.setSegmentCode}/>
                      </div>}
                  {this.state.hasSectionAccess && this.state.sections.length > 0 &&
                      <div className="third-row-inner-container">
                          <h5>Section</h5>
                          <DropdownComponent
                              disabled={this.props.isScheduled && id}
                              data={this.state.sections}
                              selected={this.getSelectedSection(contentCategory)}
                              onChange={this.onDropdownChange}/>
                      </div>}
                </div>
              { this.props.isScheduled &&
                <div className="fifth-row-container">
                  <div className="start-date-container">
                    <h5>Start Date</h5>
                    <DatePicker
                      className="modal-schedule-date-picker"
                      format={DATEPICKER_DATE_FORMAT}
                      value={data.startDate ? data.startDate : null}
                      onChange={(e) => this.updateProperty('startDate', e.target.value)}
                    />
                  </div>
                  <div className="end-date-container">
                    <h5>End Date</h5>
                    <DatePicker
                      className="modal-schedule-date-picker"
                      format={DATEPICKER_DATE_FORMAT}
                      value={data.endDate ? data.endDate : null}
                      onChange={(e) => this.updateProperty('endDate', e.target.value)}
                    />
                  </div>
                </div>
              }
                <div className="tags-container">
                  <div className="custom-tag-editor title-words">
                    <h5>Tags</h5>
                    <Input
                      max={50}
                      id="custom-tag-input"
                      className="tag-input"
                      placeholder={'Type new tags here'}
                      onKeyUp={(event) => {
                        if (event.key === 'Enter') {
                          const target = event.target as HTMLInputElement
                          this.addTag(target.value)
                          target.value = ''
                        }
                      }}
                    />
                    <p id="custom-input-text-bottom" className="inputtextbottom">Hit Enter When Done</p>
                    <div className="tag-container bottom-space">
                      {customTags && customTags.split(',').map(
                          (tag: string, index: number) =>
                            <Badge className="custom" key={`custom-tag-${index}`}
                                   color="secondary">{startCase(tag.toLowerCase())}
                              <span className="badge-close"
                                    onClick={(e) => this.removeTag({tag})}>x</span>
                            </Badge>)}
                    </div>
                    <div className="tag-container">
                      {nlpTags
                        && nlpTags.split(',').map((tag: string, index: number) =>
                          <Badge className="nlp" key={`nlp-tag-${index}`}
                                 color="secondary">{tag}</Badge>)}
                    </div>
                    <div className="tag-container">
                      {sourceTags
                        && sourceTags.split(',').map(
                          (tag: string, index: number) =>
                            <Badge className="source" key={`source-tag-${index}`}
                                   color="secondary">{tag}</Badge>)}
                    </div>
                  </div>
                </div>
              {  this.state.modalErrorMessage &&
                <div className="error-container">
                  {this.state.modalErrorMessage}
                </div>
              }
              </div>
            </div>
          { (this.state.hasImageGalleryAccess || this.state.isSuperUser) &&
            <ImageGalleryModal
                data={this.props.data}
                onSelectImageFromGallery={(selectedImage: {
                  url: string,
                  imageWidth: number,
                  imageHeight: number
                }) => {
                  this.setState({
                    sourceUrl: selectedImage.url,
                    validUrl: Validation.validateUrl(selectedImage.url).valid,
                    useUrl: true
                  })
                  this.updateProperty('image', selectedImage.url)
                  this.updateProperty('hostedImage', {
                    url: selectedImage.url,
                    height: selectedImage.imageHeight,
                    width: selectedImage.imageWidth,
                  })
                }}
                closeModal={this.props.closeModal}
                closeButtonText={'Cancel'}
                size={Modals.ModalSize.Large}/>
          }
          </div>
        </div>
      </div>
  }

  protected renderArticleUrlField = (data) => {
    const { id, url } = data
    return <>
      <div className="heading">
        <h5>Event Url</h5>
        {id &&
            <a href={this.state.sourceUrl || url} target="_blank" rel="noopener">
                <LinkOutIcon/>
                <span>{this.props.data.url}</span>
            </a>}
      </div>
      <div className="first-row-inner-container">
        {!id
          ? <div className="url-input-and-fetch-container">
            {
              this.state.retrievedUrl ?
                <div className="url-input-container">
                  <DropdownComponent
                    selected={url}
                    data={[
                      {key: '', description: 'Please Select'},
                      {key: this.state.originalUrl, description: 'I want exactly the url I submitted'},
                      {key: this.state.retrievedUrl, description: 'I want the one you retrieved'},
                    ]}
                    onChange={(e) => {
                      this.updateProperty('override_url', e.selected.key === this.state.originalUrl)
                      this.updateProperty('url', e.selected.key)
                    }} />
                  <span className="input-tip">
                    We retrieved a different url than the one you submitted, please select which one you'd like to use
                  </span>
                </div>
                : <UrlInput
                  disabled={!!this.state.originalUrl}
                  errorMessage={this.state.errorMessage}
                  onChange={this.updateSourceUrl}
                  onKeyPress={this.handleKeyPress}
                  placeholder={'Please input your article url here'}
                  valid={this.state.validUrl}
                  value={this.state.sourceUrl || url}/>
            }
            <div className={classnames(this.getClassNames(data, CONTROL_FETCH_BUTTON))}>
              {this.state.originalUrl ?
                <span className={classnames('fetch-btn clickable-item', this.state.validUrl ? 'valid-url' : '')}
                      onClick={this.resetModal}>
                                <label className="clickable-item">
                                    Reset
                                </label>
                            </span>
                :
                <span className={classnames('fetch-btn clickable-item', this.state.validUrl ? 'valid-url' : '')}
                      onClick={() => this.validateFetch(this.state.sourceUrl || url)}>
                                <label className="clickable-item">
                                    Fetch
                                </label>
                            </span>}
            </div>
          </div>
          : <div className="url-display-box">
            {this.state.sourceUrl || url}
          </div>}
      </div>
    </>
  }
  protected createOrUpdate(payload: any): Promise<any> {
    const baseEntityName = this.props.isScheduled ? 'scheduledContent' : 'rasaCommunityArticle'
    return this.context.entityMetadata
      .getEntityObject(baseEntityName, payload.communityId, null)
      .then((entity: BaseClientEntity) => {
        const {typeId} = payload
        entity.setRecordId(payload.communityId, typeId && !payload.isCloneItem ? typeId : null)
        entity.data.format_type_id = FormatTypes.event
        entity.data.url = payload.url
        entity.data.override_url = payload.override_url
        entity.data.post_subscription_id = payload.post_subscription_id

        if (this.props.isScheduled) {
          entity.data.contentType = ScheduledContentTypes.article
          entity.data.endDate = endOfDay(payload.endDate, payload.timezone)
          entity.data.startDate = startOfDay(payload.startDate, payload.timezone)
          entity.data.rank = 1
          entity.data.boost_level = SCHEDULED_ARTICLES_BOOST_LEVEL
          entity.data.featureType = payload.featureType || 'Lead'
        }

        if ( this.fieldChanged('description') ) {
          entity.data.description = payload.description
        }
        if ( this.fieldChanged('title') ) {
          entity.data.title = payload.title
        }
        if ( this.fieldChanged('publisher') ) {
          entity.data.site_name = payload.publisher
        }
        if ( this.fieldChanged('image_alt_text') ) {
          entity.data.image_alt_text = payload.image_alt_text
        }
        if (payload.options && this.fieldChanged('options') ) {
          entity.data.options = JSON.stringify(payload.options)
        }
        if (payload.hostedImage && this.fieldChanged('hostedImage')) {
          entity.data.hosted_image_url = payload.hostedImage.url
          entity.data.image_height = payload.hostedImage.height
          entity.data.image_width = payload.hostedImage.width
          entity.data.image_url = payload.hostedImage.url
        } else if (payload.image && this.fieldChanged('image')) {
          entity.data.image_url = payload.image
        }
        if (payload.isReserved) {
          entity.data.is_reserved = true
        }
        entity.data.custom_tags = payload.customTags
        entity.data.content_category =  payload.contentCategory
        entity.data.category_type_id = payload.categoryTypeId
        entity.data.community_partner_code = payload.community_partner_code
        if (!payload.typeId) {
          // Only do this if we are creating a new article
          // Pass community_identifier
          entity.data.communityIdentifier =  payload.communityId
          if (isInCutoffWindow(this.state.activeCommunity.nextIssue)) {
            // Mark the article as boosted if it is in the cutoff window and set the created date to the cutoff date
            entity.data.boost_level = NORMAL_BOOSTED_BOOST_LEVEL
            entity.data.created = this.state.activeCommunity.nextIssue.cutoff
          }
        }
        return entity.save()
      })
  }

  protected doSave(data: any): Promise<any> {
    const articlePromise = [super.doSave(data)]
    if (data.forceUpdate && data.forceUpdate.length) {
      const duplicateArticle = data.forceUpdate.map((e) => {
        const cloneArticle = {
          typeId: e.id,
          title: data.title,
          description: data.description,
          image_url: data.image_url,
          hosted_image_url: data.hosted_image_url,
          image_height: data.image_height,
          image_width: data.image_width,
          image_alt_text: data.image_alt_text,
          customTags: data.custom_tags,
          hostedImage: data.hostedImage,
          communityId: data.communityId,
          options: data.options,
        }
        return this.createOrUpdate(cloneArticle)
      })
      articlePromise.push(...duplicateArticle)
    }
    return Promise.all(articlePromise)
  }

  protected getTitleText(data: any): string {
    return 'Event'
  }

}