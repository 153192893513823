import { DropdownComponent } from 'components/dropdown/component'
import { isEmpty } from 'lodash'
import React from 'react'
import * as Modals from 'shared/modals'

interface SectionSelectState extends Modals.ModalComponentState {
  selectedSection: any,
}

interface SectionSelectProps extends Modals.ModalComponentProps {
  onSave: (section: any) => any
}

export class SectionSelectModal extends Modals.GenericModalComponent<SectionSelectProps, SectionSelectState> {
  public static key: string = 'sectionSelect'
  constructor(props: any) {
    super(props, SectionSelectModal.key)
    this.state = {
      selectedSection: null,
    }
  }

  protected renderChildren(data: any) {
    return <div>
              <DropdownComponent data={this.normalizeOptions(data)}
                  selected={this.state.selectedSection ? this.state.selectedSection.key : ''}
                  onChange={(e) => this.onDropdownChange(e)}/>
            </div>
  }

  protected save(data: any) {
    this.props.onSave(this.state.selectedSection)
    this.props.closeModal(SectionSelectModal.key)
  }

  private onDropdownChange(e) {
    this.setState({
      selectedSection: e.selected,
    })
  }

  private normalizeOptions(sections) {
    if (!isEmpty(sections)) {
      return sections.map((s) => {
        return {
          description: s.name,
          key: s.id,
          value: s.category_type,
        }
      })
    } else {
      return []
    }
  }

}
