import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import { HeaderComponent } from 'components/header/component'
import { Books } from 'components/icons/books'
import { FacebookIcon } from 'components/icons/facebook'
import { GoogleNewsIcon } from 'components/icons/google-news'
import { InstagramIcon } from 'components/icons/instagram'
import { LinkedInIcon } from 'components/icons/linkedin'
import { NextIcon } from 'components/icons/next'
import { PocketLogo } from 'components/icons/pocket'
import { PodcastIcon } from 'components/icons/podcast'
import { RSSFeedIcon } from 'components/icons/rssfeed'
import { TRexIcon } from 'components/icons/t-rex'
import { UpContentLogo } from 'components/icons/upcontent'
import { YoutubeLogo } from 'components/icons/youtube'
import { ZoomIcon } from 'components/icons/zoom'
import { AnchorLink } from 'components/link'
import { Loading } from 'components/loading'
import { UpgradeComponent } from 'components/upgrade/component'
import { RasaContext } from 'context'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import { registerNewNestedComponent } from 'generic/genericRedux'
import * as GA from 'google-analytics'
import { orderBy } from 'lodash'
import React from 'react'
import { Col, Row } from 'reactstrap'
import { CommunitySystem, getCommunityIntegrations, IntegrationSystems } from 'shared/data-layer/integration'
import { Roles } from 'shared/constants'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { SourceTypes } from 'shared_server_client/constants'
import * as BillingPlan from 'shared_server_client/types/billing_plan'
import { ADD_SOURCE_ADDITIONAL_FEATURES } from '../upgrade/constants';
import './_styles.scss'
import * as Constants from './constants'
import { ContentPoolAddTwitterSource } from './twitter/add-source'

declare const RASA_IMAGE_API_ENDPOINT: string
enum SourceSelected {
  none,
  facebook,
  rss,
  twitter,
}
interface AddSourceState {
  isLoading: boolean,
  plan: BillingPlan.BillingPlan,
  selected: SourceSelected,
  accountSourcesCount: number,
}

class ContentPoolAddSourceComponent extends React.Component<any, AddSourceState> {
  public static contextType = RasaContext;
  private sharedStore: SharedStore

  constructor(props: any) {
    super(props)
    this.state = {
      accountSourcesCount: 0,
      isLoading: true,
      plan: null,
      selected: SourceSelected.none,
    }
  }

  public componentDidMount() {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.person),
    ])
    .then(([activeCommunity, person]) => {
      this.getSources(activeCommunity.communityId)
        .then((orderedSources) => {
          this.props.datasetLoaded(orderedSources)
          this.setState({
            accountSourcesCount: activeCommunity.billingInfo.usageStats.sourcesCount,
            isLoading: false,
            plan: activeCommunity.billingInfo.currentPlan,
          })
      })
    })
  }

  public render() {
    if (this.state.isLoading) {
      return (<Loading size="64" />)
    }
    const maxSourceCount = BillingPlan.getCurrentPlanMaxSources(this.state.plan)
    if (this.state.accountSourcesCount >= maxSourceCount) {
      return <UpgradeComponent
        source={GA.UpgradeSource.AddSource}
        features={ADD_SOURCE_ADDITIONAL_FEATURES}
        description="Unlock more sources and features when you upgrade your plan" />
    }
    return <div className="add-source-wrapper">
      <Row>
        <Row>
          { this.state.selected === SourceSelected.none &&
          <SourceSelectorComponent selectSource={this.selectSource}/>}
          { this.state.selected === SourceSelected.twitter &&
          <ContentPoolAddTwitterSource/> }
        </Row>
      </Row>
    </div>
  }

  private selectSource = (selected: SourceSelected) => {
    this.setState({
      ...this.state,
      selected,
    })
  }

  private getSources = (communityIdentifier) => {
    const url: string = AjaxWrapper.getServerUrl() + `/dataset/${communityIdentifier}/communitySources`
    return AjaxWrapper.ajax(url, HttpMethod.GET, null)
    .then((response) => {
      return orderBy(response[0], ['is_active'], 'desc')
    })
  }
}

export const ContentPoolAddSource = registerNewNestedComponent(
  ContentPoolAddSourceComponent, 'add_source', {})

interface SourceSelectorProps {
  selectSource: any,
}

interface SourceSelectorState {
  activeCommunity: any,
  instagramSystem: CommunitySystem,
  linkedInSystem: CommunitySystem,
  pocketRssSystem: CommunitySystem,
  isLoading: boolean,
  isSuperUser: boolean,
  person: any,
  plan: BillingPlan.BillingPlan,
  zoomSystem: CommunitySystem,
}

class SourceSelectorComponent extends React.Component<SourceSelectorProps, SourceSelectorState> {
  public static contextType = RasaContext;
  private sharedStore: SharedStore

  constructor(props: SourceSelectorProps) {
    super(props)

    this.state = {
      activeCommunity: null,
      instagramSystem: null,
      linkedInSystem: null,
      pocketRssSystem: null,
      isLoading: true,
      isSuperUser: false,
      person: null,
      plan: null,
      zoomSystem: null,
    }
  }

  public componentDidMount() {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.person),
      this.sharedStore.getValue(SharedKeys.role),
    ])
    .then(([activeCommunity, person, role]) => {
      this.setState({
        activeCommunity,
        isSuperUser: role === Roles.super_admin,
        person,
      })
      getCommunityIntegrations(activeCommunity, person)
        .then((allIntegrations: CommunitySystem[]) => {
          this.setState({
            instagramSystem: allIntegrations.find((i: CommunitySystem) => i.name === IntegrationSystems.Instagram),
            isLoading: false,
            linkedInSystem: allIntegrations.find((i: CommunitySystem) => i.name === IntegrationSystems.LinkedIn),
            plan: activeCommunity.billingInfo.currentPlan,
            pocketRssSystem: allIntegrations.find((i: CommunitySystem) => i.name === IntegrationSystems.PocketRss),
            zoomSystem: allIntegrations.find((i: CommunitySystem) => i.name === IntegrationSystems.Zoom),
          })
        })
    })
  }

  public render = () => (
    this.state.isLoading ? <Loading size="64" /> :
    <div>
      <div className="header-container">
        <HeaderComponent
          title={'SOURCES'}
          subTitle={'Add New Content'}
          description={[
            'Automatically pull in new content from a source.  Consider adding a filter if the source is broad.',
          ]}
        />
      </div>
      <Row className='addsource'>
        <Col className="left-col">
          <Row>
            <div className="blog sources">
            <AnchorLink href={`${DashboardMenuOption.content}/add-rss`} ga4Event={this.makePickEvent('rss')}>
              <div className="image">
                <RSSFeedIcon/>
              </div>
              <div className="words">
                <h1>Blog or RSS feed</h1>
                <p>Showcase links or articles posted on a Blog or plug in any RSS feed.</p>
              </div>
              <div className="nexticon">
                <NextIcon/>
              </div>
            </AnchorLink>
            </div>
          </Row>
          <Row>
            <div className="youtube sources">
            <AnchorLink href={`${DashboardMenuOption.content}/add-youtube`} ga4Event={this.makePickEvent('youtube')}>
              <div className="image">
                <YoutubeLogo />
              </div>
              <div className="words">
                <h1>YouTube Channel</h1>
                <p>Showcase videos posted on a YouTube channel.</p>
              </div>
              <div className="nexticon">
                <NextIcon/>
              </div>
            </AnchorLink>
            </div>
          </Row>
          <Row>
            <div className="facebook sources">
              <AnchorLink href={`${DashboardMenuOption.content}/add-facebook`}
                          ga4Event={this.makePickEvent('facebook')}>
                <div className="image">
                  <FacebookIcon svgwidth={16.48} svgheight={16.48}/>
                </div>
                <div className="words">
                  <h1>Facebook</h1>
                  <p>Showcase links or articles you've posted on your Facebook Page.</p>
                </div>
                <div className="nexticon">
                  <NextIcon/>
                </div>
              </AnchorLink>
            </div>
          </Row>
          { this.state.instagramSystem ?
          <Row>
            <div className="instagram sources">
              <AnchorLink href={this.getInstagramHref()} ga4Event={this.makePickEvent('instagram')}>
                <div className="image">
                  <InstagramIcon svgwidth={16.48} svgheight={16.48}/>
                </div>
                <div className="words">
                  <h1>{SourceTypes.instagram}</h1>
                  <p>Showcase links or articles you've posted on your Instagram.</p>
                </div>
                <div className="nexticon">
                  <NextIcon/>
                </div>
              </AnchorLink>
            </div>
          </Row>
          : null
          }
          { this.state.linkedInSystem ?
          <Row>
            <div className="linkedin sources">
              <AnchorLink href={this.getLinkedInHref()} ga4Event={this.makePickEvent('linkedin')}>
                <div className="image">
                  <LinkedInIcon svgwidth={16.48} svgheight={16.48}/>
                </div>
                <div className="words">
                  <h1>{SourceTypes.linkedIn}</h1>
                  <p>Showcase links or articles you've posted on your Organization's LinkedIn.</p>
                </div>
                <div className="nexticon">
                  <NextIcon/>
                </div>
              </AnchorLink>
            </div>
          </Row>
          : null
          }
          { this.state.zoomSystem ?
          <Row>
            <div className="zoom sources">
              <AnchorLink href={this.getZoomSystemHref()} ga4Event={this.makePickEvent('zoom')}>
                <div className="image">
                  <ZoomIcon />
                </div>
                <div className="words">
                  <h1>{SourceTypes.zoom}</h1>
                  <p>Showcase webinars you've created on your Zoom.</p>
                </div>
                <div className="nexticon">
                  <NextIcon/>
                </div>
              </AnchorLink>
            </div>
          </Row>
          : null
          }
        </Col>
        <Col className="right-col">
          <Row>
            <div className="google sources">
              <AnchorLink href={`${DashboardMenuOption.content}/add-google`}
                          ga4Event={this.makePickEvent('google')}>
                <div className="image">
                  <GoogleNewsIcon svgwidth={40} svgheight={40} />
                </div>
                <div className="words">
                  <h1>Google News</h1>
                  <p>Showcase articles from Topics or Search Terms from Google News.</p>
                </div>
                <div className="nexticon">
                  <NextIcon/>
                </div>
              </AnchorLink>
            </div>
          </Row>
          <Row>
            <div className="podcast sources">
              <AnchorLink href={`${DashboardMenuOption.content}/add-podcast`}
                          ga4Event={this.makePickEvent('podcast')}>
                <div className="image">
                  <PodcastIcon />
                </div>
                <div className="words">
                  <h1>Podcast</h1>
                  <p>Showcase episodes from Podcast.</p>
                </div>
                <div className="nexticon">
                  <NextIcon/>
                </div>
              </AnchorLink>
            </div>
          </Row>
          <Row>
            <div className="upcontent sources">
              <AnchorLink href={`${DashboardMenuOption.content}/add-upcontent`}
                          ga4Event={this.makePickEvent('upcontent')}>
                <div className="image">
                  <UpContentLogo svgwidth={40} svgheight={40} />
                </div>
                <div className="words">
                  <h1>UpContent RSS feed</h1>
                  <p>Showcase links or articles posted on a Blog or plug in any RSS feed.</p>
                </div>
                <div className="nexticon">
                  <NextIcon/>
                </div>
              </AnchorLink>
            </div>
          </Row>
          <Row>
            <div className="pocket sources">
              <AnchorLink href={this.getPocketRssHref()} ga4Event={this.makePickEvent('pocket')}>
                <div className="image">
                  <PocketLogo svgwidth={40} svgheight={40} />
                </div>
                <div className="words">
                  <h1>Pocket RSS feed</h1>
                  <p>Showcase links or articles posted on a Blog or plug in any RSS feed.</p>
                </div>
                <div className="nexticon">
                  <NextIcon/>
                </div>
              </AnchorLink>
            </div>
          </Row>
          <Row>
            <div className="sources topic">
              <AnchorLink href={`${DashboardMenuOption.content}/add-topic`}
                          ga4Event={this.makePickEvent('topic')}>
                <div className="image">
                  <i className="fa fa-list fa-2" aria-hidden="true"></i>
                  <img src={`${RASA_IMAGE_API_ENDPOINT}/hosted-images-newsbrief/path/rasa/beta-icon.jpg?h=30&w=50`}/>
                </div>
                <div className="words">
                  <h1>Topics</h1>
                  <p>Specify topics of interest, and t-rex will help find content!</p>
                </div>
                <div className="nexticon">
                  <NextIcon/>
                </div>
              </AnchorLink>
            </div>
          </Row>
        </Col>
      </Row>
      <Row className='addsource'>
        <Col className="left-col">
          <Row>
            <div className={`contentlib top-margin sources`}>
            <AnchorLink href={`${DashboardMenuOption.content}/library`}
                        ga4Event={this.makeContentToolEvent()}>
              <div className="image">
                <Books svgwidth={50} svgheight={50}/>
              </div>
              <div className="words">
                <h1>Content Library</h1>
                <p>Need help finding sources to add? Explore our Content Library here!</p>
              </div>
              </AnchorLink>
            </div>
          </Row>
        </Col>
        <Col className="right-col">
          <Row>
            <div className="suggested sources">
            <AnchorLink href={`${DashboardMenuOption.content}/suggest`}
                        ga4Event={this.makeSuggestContentEvent()}>
              <div className="image">
                <TRexIcon svgwidth={50} svgheight={50}/>
                <img src={`${RASA_IMAGE_API_ENDPOINT}/hosted-images-newsbrief/path/rasa/beta-icon.jpg?h=30&w=50`}/>
              </div>
              <div className="words">
                <h1>t-rex </h1>
                <p>Need help finding content? Leverage our AI to find new content.</p>
              </div>
              </AnchorLink>
            </div>
          </Row>
        </Col>
      </Row>
    </div>)

  private getInstagramHref = () => {
    if (this.state.instagramSystem) {
      const addInstagramPage = Constants.CONTENT_ADD_INSTAGRAM_PAGE
      if (this.state.instagramSystem.is_active) {
        return addInstagramPage
      } else {
        if (this.state.instagramSystem.connected) {
          return `${Constants.INTEGRAGTIONS_CONFIG_PAGE}?id=${this.state.instagramSystem.community_system_id}&ref=${addInstagramPage}`
        } else {
          return `${Constants.INTEGRAGTIONS_CONFIG_PAGE}?systemName=${this.state.instagramSystem.name}&displayName=${this.state.instagramSystem.display_name}&ref=${addInstagramPage}`
        }
      }
    }
  }

  private getLinkedInHref = () => {
    if (this.state.linkedInSystem) {
      const addLinkedInPage = Constants.CONTENT_ADD_LINKEDIN_PAGE
      if (this.state.linkedInSystem.is_active) {
        return addLinkedInPage
      } else {
        if (this.state.linkedInSystem.connected) {
          return `${Constants.INTEGRAGTIONS_CONFIG_PAGE}?id=${this.state.linkedInSystem.community_system_id}&ref=${addLinkedInPage}`
        } else {
          return `${Constants.INTEGRAGTIONS_CONFIG_PAGE}?systemName=${this.state.linkedInSystem.name}&displayName=${this.state.linkedInSystem.display_name}&ref=${addLinkedInPage}`
        }
      }
    }
  }

  private getZoomSystemHref = () => {
    if (this.state.zoomSystem) {
      const addPage = Constants.CONTENT_ADD_ZOOM_PAGE
      if (this.state.zoomSystem.is_active) {
        return addPage
      } else {
        if (this.state.zoomSystem.connected) {
          return `${Constants.INTEGRAGTIONS_CONFIG_PAGE}?id=${this.state.zoomSystem.community_system_id}&ref=${addPage}`
        } else {
          return `${Constants.INTEGRAGTIONS_CONFIG_PAGE}?systemName=${this.state.zoomSystem.name}&displayName=${this.state.zoomSystem.display_name}&ref=${addPage}`
        }
      }
    }
  }

  private getPocketRssHref = () => {
    if (this.state.pocketRssSystem) {
      if (this.state.pocketRssSystem.connected) {
        return `${Constants.INTEGRAGTIONS_CONFIG_PAGE}?id=${this.state.pocketRssSystem.community_system_id}`
      } else {
        return `${Constants.INTEGRAGTIONS_CONFIG_PAGE}?systemName=${this.state.pocketRssSystem.name}&displayName=${this.state.pocketRssSystem.display_name}`
      }
    }
  }

  private makeContentToolEvent = (): GA.Event4 => {
    return {
      name: GA.EventNames.OpenContentTool,
      payload: {
        source: 'add-source',
      },
    }
  }

  private makeSuggestContentEvent = (): GA.Event4 => {
    return {
      name: GA.EventNames.TRexLaunch,
      payload: {
        source: 'add-source',
      },
    }
  }

  private makePickEvent = (sourceType: string): GA.Event4 => {
    return {
      name: GA.EventNames.PickSource,
      payload: {
        action_type: sourceType,
        source: 'add-source',
      },
    }
  }
}
