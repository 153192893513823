import * as React from 'react'
import { Button, Col, Row } from 'reactstrap'

import { Loading } from 'components/loading'
import * as GenericRedux from 'generic/genericRedux'
import {Post} from '../../edit-article/types'
import { ContentPoolAddArticleComponent } from '../components'
import { ArticleAlertModal } from '../modals/articleAlert'
class MobileContentPoolAddArticleComponent extends ContentPoolAddArticleComponent {

  public render = () => {
    return <div className="mobile-add-article">
      <h6>Article URL</h6>
      <div className="mobile-article-url-wrapper">
        <div className="modal-wrapper">
          <ArticleAlertModal data={this.props.modals} closeModal={this.props.closeModal} title="Alert"/>
        </div>
      <div className="mobile-article-url">
        <input value={this.props.data.url}
               onKeyDown={(e: any) => this.handleOnEnter(e)}
               onChange={(e: any) => this.urlChanged(e.target.value)}/>
      </div>
      <Button className="mobile-reload-button"
                value="Verify"
                disabled={!this.validUrl()}
                onClick={() => this.fetchArticle()}>FETCH</Button>
      </div>
      <br/>
      { this.props.data.title &&
        <div className="mobile-new-article-mode">
          <div id="details" >
            <h6>Title</h6>
            <div className="mobile-article-desc">{this.props.data.title}</div><br/>

            <h6>Excerpt</h6>
            <div className="mobile-article-desc">{this.props.data.description}</div><br/>

            <h6>Article Preview:</h6>
            <div className="mobile-article-desc">
              <Row>
              <Col sm={3}>
                {
                  this.props.data.image ?
                  <div className="mobile-new-article-image-container">
                    <img src={this.props.data.image} height={125} />
                  </div>
                  : <div className="no-image">Add an image</div>
                }
              {/* <label htmlFor="image">{this.props.data.image ? 'Change Image' : 'Add Image'}</label>
              <input type="file"
                     className="hidden"
                     onChange={(e) => this.uploadImage(e.target.files[0])}
                     id="image"
                     name="image"
                     accept="image/png, image/jpeg">
              </input> */}
              </Col>
              <Col lg={9}>
              <b>{this.props.data.title}</b><br/>
              {this.props.data.description}
              </Col>
              </Row>
              </div><br/>
                {this.state.isSaving ?
                <div >
                  <Loading size="64"/>
                </div>
                :
                <Button value="Add Article"
                        disabled={!this.validArticle()}
                        onClick={() => this.saveArticle()}>
                    Add Article
                </Button>
                }
            </div>
            {this.state.isLoading && !this.props.data.title &&
            <div>
              <Loading size="64"/>
            </div>}
        </div>}
    </div>
  }

}

export const MobileContentPoolAddArticle = GenericRedux.registerNewComponentWithModals<Post>(
  MobileContentPoolAddArticleComponent, 'add_article', [ArticleAlertModal.key]);
