import { UrlInput } from 'components/input/component'
import { INVALID_IMAGE_URL_MESSAGE, SERVER_ERROR_MESSAGE } from 'components/schedule-editor/scheduled-content/constants'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import * as React from 'react'
import { Button } from 'reactstrap'

interface ArticleImageUrlInputProps {
  isUploading: boolean,
  updateImageUrl?: any,
  cancelUpload: any,
}

interface ArticleImageUrlInputState {
  errorMessage: string,
  imageUrl: string,
  isUploading: boolean,
  validUrl: boolean,
}
export class ArticleImageUrlInput extends React.Component<ArticleImageUrlInputProps, ArticleImageUrlInputState> {
  constructor(props: any) {
    super(props)
    this.state = {
      errorMessage: '',
      imageUrl: '',
      isUploading: false,
      validUrl: false,
    }
  }
  public render() {
    return <div className="url-container">
          <UrlInput
            errorMessage={this.state.errorMessage}
            onChange={(e) => this.setState({
              imageUrl: e.target.value},
            )}
            onKeyPress={this.handleKeyPress}
            placeholder={'Please input your image url here'}
            valid={this.state.validUrl}
            value={this.state.imageUrl}/>
          {this.renderUploadAndCancel()}
        </div>
  }

  private renderUploadAndCancel = () => {
    return <div className="upload-cancel-btns">
      <Button className="generate"
        onClick={() => this.validateFetch(this.state.imageUrl)}>
        <span>Upload</span>
      </Button>
      <Button className="generate"
        onClick={this.cancelUpload}>
        <span>Cancel</span>
      </Button>
    </div>
  }

  private fetch = (inputUrl: string): Promise<any> => {
    const imageValidateEndpoint: string = AjaxWrapper.getServerUrl() + '/image/validate-url'
    return AjaxWrapper.ajax(imageValidateEndpoint, HttpMethod.POST, {url: inputUrl})
  }

  private validateFetch = (inputUrl: string) => {
    this.setState({isUploading: true})
    return this.fetch(inputUrl)
    .then((result: any) => {
      if (result) {
        this.setState({
          imageUrl: '',
          isUploading: false,
        })
        this.props.updateImageUrl(inputUrl)
      } else {
        this.setState({
          errorMessage: INVALID_IMAGE_URL_MESSAGE,
          isUploading: false,
          validUrl: false,
        })
      }
    })
    .catch((err) => {
        this.setState({
          errorMessage: SERVER_ERROR_MESSAGE,
          isUploading: false,
          validUrl: false,
        })
    })
  }

  private cancelUpload = () => {
    this.props.cancelUpload()
  }

  private handleKeyPress = (target: any) => {
    if (target.charCode === 13) {
      this.validateFetch(this.state.imageUrl)
    }
  }
}
