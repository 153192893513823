import { DatePicker } from '@progress/kendo-react-dateinputs'
import { RasaContext } from 'context'
import { BaseClientEntity } from 'generic/baseClientEntity'
import { isFalsey } from 'generic/utility'
import React from 'react'
import { DATEPICKER_DATE_FORMAT } from 'shared/constants'
import * as Modals from 'shared/modals'
import { SCHEDULED_ARTICLES_BOOST_LEVEL, ScheduledContentTypes } from 'shared_server_client/constants'
import { endOfDay,  startOfDay } from 'shared_server_client/dates'
interface ScheduleArticleModalState extends Modals.ModalComponentState {
  isDirty: boolean,
}

interface ScheduleArticleModalProps extends Modals.ModalComponentProps {
  redirect: () => any
}

export class ScheduleArticleModal
  extends Modals.GenericModalComponent<ScheduleArticleModalProps, ScheduleArticleModalState> {
  public static key: string = 'scheduleArticle'
  public static contextType = RasaContext

  constructor(props: any) {
    super(props, ScheduleArticleModal.key)
  }
  protected renderChildren(data: any) {
    const { endDate, startDate } = data
    return <div>
            <div className="date-pickers-container">
              <div className="start-date-container">
                <h5>Start Date</h5>
                <DatePicker
                  className="modal-schedule-date-picker"
                  format={DATEPICKER_DATE_FORMAT}
                  value={startDate ? startDate : null}
                  onChange={(e) => this.updateProperty('startDate', e.target.value)}
                />
              </div>
              <div className="end-date-container">
                <h5>End Date</h5>
                <DatePicker
                  className="modal-schedule-date-picker"
                  format={DATEPICKER_DATE_FORMAT}
                  value={endDate ? endDate : null}
                  onChange={(e) => this.updateProperty('endDate', e.target.value)}
                />
              </div>
            </div>
    </div>
  }

  protected getSaveText(data: any): string {
    return 'Schedule & Save'
  }

  protected saveDisabled(data: any): boolean {
    const { endDate, startDate } = data
    return isFalsey(endDate && startDate)
  }

  protected updateProperty(property, value) {
    this.props.updateModal(ScheduleArticleModal.key, {
      name: property,
      value,
    })
    this.setState({ isDirty: !this.state.isDirty })
  }

  protected doSave(data: any) {
    return this.createOrUpdate(data)
      .then(() => {
      this.setState({})
      this.props.closeModal(ScheduleArticleModal.key)
      this.props.redirect()
      return Promise.resolve({})
    })
    .catch((error) => {
      return Promise.reject({})
    })
  }

  private createOrUpdate(payload: any): Promise<any> {
    return this.context.entityMetadata
    .getEntityObject('scheduledContent', payload.communityId, null)
    .then((scheduledContentEntityObject: BaseClientEntity) => {
      const {description, endDate, site_name, startDate, title, url} = payload
      scheduledContentEntityObject.setRecordId(payload.communityId, null)
      scheduledContentEntityObject.data.contentType = ScheduledContentTypes.article
      scheduledContentEntityObject.data.endDate = endOfDay(endDate, payload.timezone)
      scheduledContentEntityObject.data.startDate = startOfDay(startDate, payload.timezone)
      scheduledContentEntityObject.data.url = url
      scheduledContentEntityObject.data.override_url = payload.override_url
      scheduledContentEntityObject.data.description = description
      scheduledContentEntityObject.data.title = title
      scheduledContentEntityObject.data.site_name = site_name
      scheduledContentEntityObject.data.rank = 1
      scheduledContentEntityObject.data.boost_level = SCHEDULED_ARTICLES_BOOST_LEVEL
      scheduledContentEntityObject.data.featureType = 'Lead'
      scheduledContentEntityObject.data.communityIdentifier =  payload.communityId
      scheduledContentEntityObject.data.content_category =  payload.content_category
      scheduledContentEntityObject.data.content_type =  'Lead'
      return scheduledContentEntityObject.save()
    })
  }

}
