import { Loading } from 'components/loading'
import * as React from 'react'
import { EmailTemplates } from 'shared_server_client/constants'
import { ArticleImageUrlInput } from './article-image-url-input'
import { Font, IMAGE_MAY_BE_TOO_SMALL_TEMPLATE_TOOLTIP, IMAGE_TOO_SMALL_TOOLTIP } from './constants'
import { imageIsTooSmall } from './utility/utils'
import { Button } from 'reactstrap'

interface ArticleImageUploadProps {
  allowAIGeneration?: boolean,
  allowUseUrl?: boolean,
  allowImageLibrary?: boolean,
  imageHeight: number,
  imageUrl: string,
  imageWidth: number,
  isUploading: boolean,
  uploadImage: any,
  clearImage: any,
  font?: Font,
  updateImageUrl?: any,
  useUrlClick?: any,
  aiGenerateImageClick?: any
  selectImageLibrary?: any
}

interface ArticleImageUploadState {
  errorMessage: string,
  imageUrl: string,
  isUploading: boolean,
  showUrlInput: boolean,
  validUrl: boolean,
}
export class ArticleImageUpload extends React.Component<ArticleImageUploadProps, ArticleImageUploadState> {
  public fileInputRef;
  constructor(props: any) {
    super(props)
    this.state = {
      errorMessage: '',
      imageUrl: '',
      isUploading: false,
      showUrlInput: false,
      validUrl: false,
    }
    this.fileInputRef = React.createRef()
  }
  public render() {
    return this.props.isUploading ?
      <div>
        <Loading size="64"/>
      </div>
    :
    <div className="upload-section">
      <div className="upload-label">
        <label htmlFor="image" className="upload-label clickable-item">
        {
          this.props.imageUrl
            ? <img src={this.props.imageUrl} style={{maxWidth: this.props.font === Font.LARGE ? 400 : 100,
                maxHeight: this.props.font === Font.LARGE ? 250 : 100}}/>
            : <Button className="generate schedule-btn"
                onClick={() => this.fileInputRef.current.click()}>
                <span className={`${this.props.font}`}>Upload Image</span>
              </Button>
        }
        </label>
        {this.props.allowUseUrl && !this.props.imageUrl &&
        <Button className="generate schedule-btn"
          onClick={this.inputImageUrlClick}>
          <span className={`${this.props.font}`}>Input Image URL</span>
        </Button>
        }
        {!this.props.imageUrl && this.props.allowImageLibrary &&
            <Button className="generate schedule-btn"
                    onClick={this.selectImageLibraryClick}>
                <span>Image Library</span>
            </Button>}
        {!this.props.imageUrl && this.props.allowAIGeneration &&
        <Button className="generate schedule-btn"
          onClick={this.aiGenerateImageClick}>
          <span>AI Generated</span>
        </Button>}
      </div>
      {this.state.showUrlInput && !this.props.imageUrl &&
        <ArticleImageUrlInput isUploading={this.props.isUploading}
          cancelUpload={this.cancelUpload} updateImageUrl={this.updateImageUrl} />
      }
      <input type="file"
            className="hidden"
            onChange={(e) => this.props.uploadImage(e.target.files[0])}
            ref={this.fileInputRef}
            id="image"
            name="image"
            accept="image/png, image/jpeg">
      </input>
      {this.props.imageUrl &&
        <Button className="generate"
          onClick={this.props.clearImage}>
          <span>Remove Image</span>
        </Button>}
      {this.props.imageUrl ? imageIsTooSmall(this.props.imageHeight, this.props.imageWidth, EmailTemplates.bigAndBold) ?
        <div className={`${this.props.font} image-too-small`}>
          {IMAGE_MAY_BE_TOO_SMALL_TEMPLATE_TOOLTIP}
        </div>
      : imageIsTooSmall(this.props.imageHeight, this.props.imageWidth, EmailTemplates.default) ?
        <div className={`${this.props.font} image-too-small`}>
          {IMAGE_TOO_SMALL_TOOLTIP}
        </div>
      : <div></div> : null}
    </div>
  }

  private inputImageUrlClick = () => {
    if (this.props.useUrlClick) {
      this.props.useUrlClick()
    } else {
      this.setState({
        showUrlInput: true,
      })
    }
  }
  private aiGenerateImageClick = () => {
    if (this.props.aiGenerateImageClick) {
      this.props.aiGenerateImageClick()
    }
  }

  private selectImageLibraryClick = () => {
    if (this.props.selectImageLibrary) {
      this.props.selectImageLibrary()
    }
  }

  private updateImageUrl = (imageUrl: string) => {
    this.setState({
      showUrlInput: false,
    })
    this.props.updateImageUrl(imageUrl)
  }

  private cancelUpload = () => {
    this.setState({
      showUrlInput: false,
    })
  }
}
